<script>
import AuthLayout from '@/layout/AuthLayout'
import AuthService from '@/services/auth.service'
import { isEmail } from '@/utils/validators'

export default {
	components: {
		AuthLayout,
	},
	data() {
		return {
			email: '',
		}
	},
	methods: {
    isEmail,
		handleRequest() {
			if (!this.isEmail(this.email)) {
        this.$notify({
						message: this.$t('m.pleaseEnterACorrectEmail'),
						icon: 'far fa-bell',
						horizontalAlign: 'right',
						verticalAlign: 'top',
						type: 'warning',
					})
        return
      }
      this._forgotPasswordRequest()
		},
		_forgotPasswordRequest() {
			AuthService.forgotPassword(this.email)
				.then(res => {
          this.$router.push('/')
					this.$notify({
						message: this.$t('m.aResetPasswordLinkHasBeenSentToYourEmail'),
						icon: 'far fa-bell',
						horizontalAlign: 'right',
						verticalAlign: 'top',
						type: 'success',
					})
				})
		},
	},
}
</script>

<template>
  <AuthLayout :title="$t('forgotYourPassword')">
    <form
      name="form"
      @submit.prevent="handleRequest"
    >
      <BaseInput
        v-model="email"
        :label="$t('emailOrUsername')"
        :placeholder="$t('enterYourEmailOrUsername')"
        type="text"
        autocomplete="email"
      />
      <BaseButton
        slot="footer"
        type="blue"
        gradient
        native-type="submit"
        class="button"
        fill
        @click="() => {}"
      >
        {{ $t('send') }}
      </BaseButton>
    </form>
    <br>
    <div>{{ $t('wantToLogin') }}</div>
    <router-link :to="{ name: 'login' }">
      {{ $t('clickHereToLogin') }}
    </router-link>
  </AuthLayout>
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

</style>

<script>
export default {
  name: "BaseButton",
  props: {
    tag: {
      type: String,
      default: "button",
      description: "Button html tag"
    },
    round: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "default",
      description: "Button type (primary|secondary|danger etc)"
    },
    nativeType: {
      type: String,
      default: "button",
      description: "Button native type (e.g button, input etc)"
    },
    size: {
      type: String,
      default: "",
      description: "Button size (sm|lg)"
    },
    simple: {
      type: Boolean,
      default: false,
      description: "Whether button is simple (outlined)"
    },
    link: {
      type: Boolean,
      default: false,
      description: "Whether button is a link (no borders or background)"
    },
    gradient: {
      type: Boolean,
      default: false,
    },
    fill: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick(evt) {
      this.$emit("click", evt)
    }
  }
}
</script>

<template>
  <component
    :is="tag"
    :type="tag === 'button' ? nativeType : ''"
    :disabled="disabled"
    :class="[
      {'btn-round': round},
      {'btn-block': block},
      {'btn-icon btn-fab': icon},
      {[`btn-${type}`]: type},
      {[`btn${type}`]: type},
      {'btngradient': gradient},
      {[`btn-${size}`]: size},
      {'btn-simple': simple},
      {'btn-link': link},
      {'disabled': disabled && tag !== 'button'},
      {'btnFill' : fill}
    ]"
    class="btn"
    @click="handleClick"
  >
    <div
      class="buttonInner"
    >
      <slot />
    </div>
  </component>
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

.button
  &Inner
    position: relative
    z-index: 1
.btn
  &Fill
    width: 100%
  &gradient
    position: relative
    background: transparent
    &::before
      position: absolute
      top: 0
      left: 0
      z-index: 0
      width: 100%
      height: 100%
      content: ''
  &green
    &::before
      background: $green-gradient
  &blue
    &::before
      background: $green-blue-purple-gradient
</style>
<script>
export default {
  name: "SidebarLink",
  inject: {
    autoClose: {
      default: true
    },
    addLink: {
      default: ()=>{}
    },
    removeLink: {
      default: ()=>{}
    }
  },
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    tag: {
      type: String,
      default: "router-link"
    }
  },
  mounted() {
    if (this.addLink) {
      this.addLink(this);
    }
  },
  beforeDestroy() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el)
    }
    if (this.removeLink) {
      this.removeLink(this);
    }
  },
  methods: {
    hideSidebar() {
      if (this.autoClose) {
        this.$sidebar.displaySidebar(false);
      }
    },
    isActive() {
      return this.$el.classList.contains("active");
    }
  },
};
</script>

<template>
  <component
    :is="tag"
    v-bind="$attrs"
    class="nav-item navItem"
    tag="li"
    @click.native="hideSidebar"
  >
    <a class="nav-link navLink">
      <slot>
        <i
          v-if="icon"
          :class="icon"
        />
        <p>{{ name }}</p>
      </slot>
    </a>
  </component>
</template>

<style lang="sass" scoped>
.nav
  .navItem
    .navLink
      padding: 4px 8px
    &.active > a:not([data-toggle="collapse"])
      &::before
        top: 15px
</style>

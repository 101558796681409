<script>
  const siteLink = process.env.VUE_APP_SITE_LINK

  export default {
    data() {
      return {
        year: new Date().getFullYear(),
        siteLink
      }
    }
  };
</script>

<template>
  <footer class="footer">
    <div class="container-fluid">
      <ul class="nav">
        <li class="nav-item">
          <a 
            class="nav-link" 
            :href="siteLink"
            target="_blank"
          >
            {{ siteLink }}
          </a>
        </li>
      </ul>
      <div class="copyright">
        © {{ year }} {{ $t('madeWith') }} <i class="tim-icons icon-heart-2" />
      </div>
    </div>
  </footer>
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

</style>

<script>
import { DataTable } from 'v-datatable-light'
import Pagination from '@/components/Pagination'

export default {
	name: 'BaseListTable',
	components: {
		DataTable,
		Pagination,
	},
	props: {
		type: {
			type: String,
			default: '',
		},
		items: {
			type: Array,
			default: () => [],
			required: true,
		},
		totalItems: {
			type: Number,
			default: null,
		},
		limit: {
			type: Number,
			default: 20,
		},
		offset: {
			type: Number,
			default: 0,
		},
		headerFields: {
			type: Array,
			default: () => [],
			required: true,
		},
		notFoundMessage: {
			type: String,
			default: '',
		},
		sort: {
			type: String,
			default: 'asc',
		},
		sortField: {
			type: String,
			default: null,
		},
		hasPagination: {
			type: Boolean,
			default: false,
		},
		onlyShowOrderedArrow: {
			type: Boolean,
			default: true,
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			datatableCss: {
				table:
					'table table-hover table-dark table-striped tableDark tableStriped tableHover',
				theadTr: 'headerItem',
				footer: 'footer',
				tfootTr: 'footerItem'
			},
			paginationCss: {
				paginationItem: 'page-item pageItem',
				pageBtn: 'page-link pageLink',
			},
			itemsPerPageCss: {
				select: 'itemPerPageDropdown',
			},
		}
	},

	methods: {
		updatePagination(currentPage) {
			this.$emit('updatePagination', currentPage)
		},
		remove(props) {
			if (confirm(this.$t('m.areYouSureYouWantToDeleteThatItem'))) {
				this.$emit('delete', props.rowData.id)
			} else {
				this.$notify({
					message: this.$t('m.youDidNotDeleteTheItem'),
					icon: "far fa-bell",
					horizontalAlign: 'right',
					verticalAlign: 'top',
					type: 'warning',
				})
			}

		},
	},
}
</script>

<template>
  <DataTable
    :header-fields="headerFields"
    :data="items"
    :not-found-msg="notFoundMessage || $t('notFound', { item: $tc('item', 2) })"
    :sort-field="sortField"
    :sort="sort"
    :css="datatableCss"
    :only-show-ordered-arrow="onlyShowOrderedArrow"
    :is-loading="isLoading"
    class="datatableLight"
    item-key="id"
  >
    <div
      slot="published"
      slot-scope="props"
    >
      <div
        :class="['published', {'isPublished': props.rowData.published === 1}]"
        :title="props.rowData.published === 1 ? $t('published') : $t('unpublished')"
      >
        <BaseIcon
          icons-group="far"
          icon="fa-check-circle"
        />
      </div>
    </div>
    <div
      slot="photo"
      slot-scope="props"
    >
      <div class="photoContainer">
        <BaseImage
          v-if="props && props.rowData && props.rowData.photo && props.rowData.photo.mini"
          :source="props.rowData"
          class="photo"
          size="mini"
          fill
        />
        <span v-else>
          <BaseIcon
            icons-group="far"
            icon="fa-image"
            class="icon iconImage"
            size="l"
          />
        </span>
      </div>
    </div>
    <div
      slot="title"
      slot-scope="props"
    >
      <div>
        <router-link
          :class="['title']"
          :to="`/${type}/${props.rowData.id}`"
        >
          {{ props.rowData.name }}
        </router-link>
      </div>
    </div>
    <template #edit="props">
      <router-link :to="`/${type}/${props.rowData.id}`">
        <BaseIcon
          icons-group="far"
          icon="fa-edit"
          class="iconEdit"
        />
      </router-link>
    </template>
    <template #delete="props">
      <div @click="remove(props)">
        <BaseIcon
          icons-group="fas"
          icon="fa-ban"
          class="iconDelete"
        />
      </div>
    </template>
    <template
      v-if="hasPagination"
      #pagination
    >
      <Pagination
        :offset="offset"
        :total-items="totalItems"
        :limit="limit"
        :total-displayed="items ? items.length : null"
        @updatePagination="updatePagination"
      />
    </template>
  </DataTable>
</template>

<style lang="scss">
@use '~@/assets/sass/design.sass' as *;

.datatableLight {
	overflow: hidden;
	border-radius: 6px;

	th, td {
		&:first-child {
			padding-left: $base-spacing;
		}

		&:last-child {
			padding-right: $base-spacing;
		}
	}

	&.tableDark {
		background: transparent;
	}

	&.tableStriped {
		background: $c-pickled-bluewood-blue;
		border: none;

		tbody tr td {
			border: none;
		}

		th {
			border: none;
		}
	}

	&.tableHover tbody tr:hover {
		background: $c-san-juan-blue;
	}

	.headerItem {
		height: $table-header;
		background: lighten($c-pickled-bluewood-blue, 2%);
	}

	.title {
		color: $c-white;

		&:hover {
			color: $c-acc-green;
		}
	}

	.icon {
		&Edit {
			color: $c-acc-blue-dark;
		}

		&Delete {
			color: $c-acc-red;
			cursor: pointer;
		}
	}

	.published {
		color: $c-burning-orange;
		text-align: center;

		&.isPublished {
			color: $c-acc-green;
		}
	}

	.photo {
		position: relative;
		z-index: 1;
		min-width: 100%;
		min-height: 100%;
		border-radius: 0 !important;

		&Container {
			position: relative;
			width: $s-xl;
			height: $s-xl;
			overflow: hidden;
			border-radius: 50%;

			&::after {
				position: absolute;
				top: 0;
				left: 0;
				z-index: 0;
				width: 100%;
				height: 100%;
				content: '';
				background: $green-blue-purple-gradient;
				opacity: 0.3;
			}
		}
	}

	.footer {
		padding: 0;
	}

	.footerItem {
		background: lighten($c-pickled-bluewood-blue, 2%);
	}
}
</style>

import { get, put } from '@/services/api.service';

const API_URL = '/settings';

class SettingsService {
  get() {
    return get(`${API_URL}?extended=1`)
      .then(res => res);
  }
  getGeneral() {
    return get(`${API_URL}?extended=1`)
      .then(res => res);
  }
  edit({ data }) {
    return put(`${API_URL}`, data)
      .then(res => res);
  }
}

export default new SettingsService();
<script>
  import SidebarLink from "./SidebarLink"
  import MiniLogo from '@/assets/images/game-medium-white-logo-with-gradient-background.png'

  export default {
    components: {
      SidebarLink
    },
    props: {
      title: {
        type: String,
        default: process.env.VUE_APP_FULL_TITLE
      },
      backgroundColor: {
        type: String,
        default: "vue"
      },
      activeColor: {
        type: String,
        default: "success",
        validator: value => {
          const acceptedValues = [
            "primary",
            "info",
            "success",
            "warning",
            "danger"
          ]
          return acceptedValues.indexOf(value) !== -1
        }
      },
      sidebarLinks: {
        type: Array,
        default: () => []
      },
      autoClose: {
        type: Boolean,
        default: true
      }
    },
    provide() {
      return {
        autoClose: this.autoClose
      }
    },
    data() {
      return {
        linkHeight: 65,
        activeLinkIndex: 0,
        windowWidth: 0,
        isWindows: false,
        hasAutoHeight: false,
        links: [],
        miniLogo: MiniLogo
      }
    },
    computed: {
      arrowMovePx() {
        return this.linkHeight * this.activeLinkIndex
      },
      shortTitle() {
        return this.title.split(' ')
          .map(word => word.charAt(0))
          .join('').toUpperCase()
      },
      allowedSidebarLinks() {
        return this.sidebarLinks.filter(link => this.$store.getters['permissions/checkIfUserHasPermission'](link.permission) || !link.permission)
      }
    },
    mounted() {
      this.$watch("$route", this.findActiveLink, {
        immediate: true
      })
    },
    methods: {
      findActiveLink() {
        this.links.forEach((link, index) => {
          if (link.isActive()) this.activeLinkIndex = index
        })
      },
    },
  }
</script>

<template>
  <div 
    :data="backgroundColor"
    class="sidebar"
  >
    <!--
            Tip 1: you can change the color of the sidebar's background using: data-background-color="white | black | darkblue"
            Tip 2: you can change the color of the active button using the data-active-color="primary | info | success | warning | danger"
        -->
    <!-- -->
    <div 
      id="style-3" 
      class="sidebar-wrapper"
    >
      <div class="logo">
        <router-link
          to="/"
          :aria-label="$t('goBackToDashboard')"
          :title="$t('goBackToDashboard')"
          class="simple-text logo-mini"
        >
          <BaseImage
            :source="miniLogo"
            size=""
            model=""
          />
        </router-link>
        <router-link
          to="/" 
          class="simple-text"
        >
          {{ title }}
        </router-link>
      </div>
      <slot />
      <ul class="nav">
        <!--By default vue-router adds an active class to each route link. This way the links are colored when clicked-->
        <slot name="links">
          <sidebar-link 
            v-for="link in allowedSidebarLinks"
            :key="link.name"
            :to="link.path"
            :name="link.name"
            :icon="link.icon"
            class="menuItem"
          >
            <i
              v-if="link.icon"
              :class="link.icon"
            />
            {{ link.name }}
            <ul
              v-if="link.submenu"
              class="submenu"
            >
              <sidebar-link 
                v-for="link2 in link.submenu"
                :key="link2.name"
                :to="link2.path"
                :name="link2.name"
                :icon="link2.icon"
                class="submenuItem"
              />
            </ul>
          </sidebar-link>
        </slot>
      </ul>
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

.menuItem
  position: relative
  z-index: $z-dropdown
.submenu
  padding: 0
  list-style: none

@include desktop
  .submenu
    position: absolute
    top: 50%
    left: 65%
    z-index: $z-dropdown
    min-width: 100%
    padding: $base-spacing
    background: $c-acc-green
    border: 1px solid transparentize($c-black, 0.9)
    border-radius: 6px
    box-shadow: $base-shadow
    transform: scale(0) translateY(-50%)
    transition: transform .3s ease-in-out
    &Item
      &:hover
        background: transparentize($c-black, 0.92)
    &::before
      position: absolute
      top: 50%
      left: -10px
      content: ''
      border-top: 10px solid transparent
      border-bottom: 10px solid transparent
      border-right:10px solid $c-acc-green
      transform: translateY(-50%)

  .menuItem
    &:hover
      .submenu
        transform: scale(1) translateY(-50%)
</style>
import rolesService from '@/services/roles.service'

export const state = {
  roles: [],
}

export const actions = {
  async getAllRoles({ commit }) {
    const res = await rolesService.getAll()
    commit('SET_ROLES', res.data)
  },
}

export const mutations = {
  SET_ROLES(state, roles) {
    state.roles = roles;
  },
}

export const getters = {
  filterRoleById: state => id => state.roles
    .find(role => role.id === id),
}
<script>
import AuthLayout from '@/layout/AuthLayout'
import AuthService from '@/services/auth.service'

export default {
	components: {
		AuthLayout,
	},
	data() {
		return {
      model: {
        password: '',
        password_confirmation: '',
        email: '',
        token: '',
      },
      minPasswordCharacters: 8
		}
  },
  mounted() {
    this.model.email = this.$route.query.email
    this.model.token = this.$route.query.token
  },
	methods: {
		handleRequest() {
      if(!this.model.password || this.model.password.length < this.minPasswordCharacters) {
        this.$notify({
          message: this.$t('m.passwordFieldMustBeAtLeastXCharacters', { chars: this.minPasswordCharacters }),
          icon: 'far fa-bell',
          horizontalAlign: 'right',
          verticalAlign: 'top',
          type: 'warning',
        })
      }
      if(!this.model.password_confirmation) {
        this.$notify({
          message: this.$t('m.confirmPasswordFieldMustBeFilled'),
          icon: 'far fa-bell',
          horizontalAlign: 'right',
          verticalAlign: 'top',
          type: 'warning',
        })
        return
      }
      if(this.model.password !== this.model.password_confirmation) {
        this.$notify({
          message: this.$t('m.passwordsDoNotMatch'),
          icon: 'far fa-bell',
          horizontalAlign: 'right',
          verticalAlign: 'top',
          type: 'warning',
        })
        return
      }
			this._resetPassword()
		},
		_resetPassword() {
			AuthService.resetPassword(this.model)
				.then(res => {
          this.$router.push('/login')
					this.$notify({
						message: this.$t('m.yourPasswordHasBeenReset'),
						icon: 'far fa-bell',
						horizontalAlign: 'right',
						verticalAlign: 'top',
						type: 'success',
					})
				})
		},
	},
}
</script>

<template>
  <AuthLayout>
    <form
      name="form"
      @submit.prevent="handleRequest"
    >
      <BaseInput
        v-model="model.password"
        :label="$t('newPassword')"
        :placeholder="$t('enterNewPassword')"
        type="password"
        autocomplete="null"
      />
      <BaseInput
        v-model="model.password_confirmation"
        :label="$t('confirmYourNewPassword')"
        :placeholder="$t('enterYourNewPasswordAgain')"
        type="password"
        autocomplete="null"
      />
      <BaseButton
        slot="footer"
        type="blue"
        gradient
        native-type="submit"
        class="button"
        fill
        @click="() => {}"
      >
        {{ $t('resetPassword') }}
      </BaseButton>
    </form>
    <br>
    <div>{{ $t('wantToLogin') }}</div>
    <router-link :to="{ name: 'login' }">
      {{ $t('clickHereToLogin') }}
    </router-link>
  </AuthLayout>
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

</style>

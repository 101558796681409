<script>
  export default {
    name: "BaseInput",
    inheritAttrs: false,
    model: {
      prop: 'value',
      event: 'input'
    },
    props: {
      label: {
        type: String,
        default: '',
      },
      value: {
        type: [String, Number],
        default: '',
      },
      // eslint-disable-next-line vue/require-default-prop
      addonRightIcon: {
        type: String,
      },
      // eslint-disable-next-line vue/require-default-prop
      addonLeftIcon: {
        type: String,
      },
      type: {
        type: String,
        default: "text",
      },
      isSliderCheckbox: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        focused: false
      }
    },
    computed: {
      hasIcon() {
        const { addonRight, addonLeft } = this.$slots
        return addonRight !== undefined || addonLeft !== undefined || this.addonRightIcon !== undefined || this.addonLeftIcon !== undefined
      },
      listeners() {
        return {
          ...this.$listeners,
          input: this.onInput,
          blur: this.onBlur,
          focus: this.onFocus
        }
      }
    },
    methods: {
      onInput(evt) {
        this.$emit('input', evt.target.value)
      },
      onFocus() {
        this.focused = true
      },
      onBlur() {
        this.focused = false
      }
    }
  }
</script>

<template>
  <div
    class="form-group"
    :class="{
      'input-group': hasIcon,
      'input-group-focus': focused
    }"
  >
    <slot name="label">
      <label
        v-if="label"
        class="control-label"
      >
        {{ label }}
      </label>
    </slot>
    <slot name="addonLeft">
      <span
        v-if="addonLeftIcon"
        class="input-group-prepend"
      >
        <div class="input-group-text">
          <i :class="addonLeftIcon" />
        </div>
      </span>
    </slot>

    <div v-if="isSliderCheckbox">
      <label
        class="switch"
      >
        <slot>
          <input
            type="checkbox"
            :value="value"
            v-bind="$attrs"
            class="form-control"
            aria-describedby="addon-right addon-left"
            v-on="listeners"
          >
        </slot>
        <span class="slider round" />
      </label>
    </div>
    <slot v-else>
      <input
        :value="value"
        v-bind="$attrs"
        class="form-control"
        aria-describedby="addon-right addon-left"
        :type="type"
        v-on="listeners"
      >
    </slot>

    <slot name="addonRight">
      <span
        v-if="addonRightIcon"
        class="input-group-append"
      >
        <div class="input-group-text">
          <i :class="addonRightIcon" />
        </div>
      </span>
    </slot>
    <slot name="helperText" />
  </div>
</template>

<style lang="sass">
@import '~@/assets/sass/design.sass'

$slider-distance: 4px
$slider-size: $s-xl

.slider
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
  cursor: pointer
  background-color: $c-san-juan-blue
  transition: .3s
  &::before
    position: absolute
    bottom: 4px
    left: 4px
    width: ($slider-size / 2) - $slider-distance
    height: ($slider-size / 2) - $slider-distance
    content: ""
    background-color: white
    transition: .3s

input
  &:checked + .slider
    background: $green-blue-purple-gradient
  &:checked + .slider::before
    transform: translateX(($slider-size / 2) - $slider-distance)

.switch
  position: relative
  display: inline-block
  width: $slider-size
  height: ($slider-size / 2) + $slider-distance
  input
    width: 0
    height: 0
    opacity: 0

.slider.round
  border-radius: ($slider-size / 2) + $slider-distance
  &::before
    border-radius: 50%
</style>
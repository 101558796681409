<script>
  export default {
    name: "BaseDropdown",
    props: {
      tag: {
        type: String,
        default: "div",
      },
      titleTag: {
        type: String,
        default: "button",
      },
      title: {
        type: String,
        default: '',
      },
      icon: {
        type: String,
        default: '',
      },
      titleClasses: {
        type: [String, Object, Array],
        default: () => '',
      },
      menuClasses: {
        type: [String, Object],
        default: () => '',
      },
      menuOnRight: {
        type: Boolean,
        default: false,
      },
      ariaLabel: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        isOpen: false
      }
    },
    methods: {
      toggleDropDown() {
        this.isOpen = !this.isOpen
        this.$emit("change", this.isOpen)
      },
      closeDropDown() {
        this.isOpen = false
        this.$emit('change', false)
      }
    }
  };
</script>

<template>
  <component
    :is="tag"
    v-click-outside="closeDropDown"
    :class="{show:isOpen}"
    class="dropdown"
    @click="toggleDropDown"
  >
    <slot
      :is-open="isOpen"
      name="title-container"
    >
      <component
        :is="titleTag"
        :class="titleClasses"
        :aria-expanded="isOpen"
        :aria-label="title || ariaLabel"
        class="dropdown-toggle btn-rotate"
        data-toggle="dropdown"
      >
        <slot
          :is-open="isOpen"
          name="title"
        >
          <i :class="icon" />
          {{ title }}
        </slot>
      </component>
    </slot>
    <ul
      :class="[{show:isOpen}, {'dropdown-menu-right': menuOnRight}, menuClasses]"
      class="dropdown-menu"
    >
      <slot />
    </ul>
  </component>
</template>

import { render, staticRenderFns } from "./BaseDraggableList.vue?vue&type=template&id=21209628&scoped=true"
import script from "./BaseDraggableList.vue?vue&type=script&lang=js"
export * from "./BaseDraggableList.vue?vue&type=script&lang=js"
import style0 from "./BaseDraggableList.vue?vue&type=style&index=0&id=21209628&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21209628",
  null
  
)

export default component.exports
<script>
import { SlideYUpTransition } from "vue2-transitions"

export default {
  name: "Modal",
  components: {
    SlideYUpTransition
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    centered: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: "", // notice | mini | ""
      validator(value) {
        const acceptedValues = ["", "notice", "mini"]
        return acceptedValues.indexOf(value) !== -1
      },
    },
    modalClasses: {
      type: [Object, String],
      default: () => '',
    },
    modalContentClasses: {
      type: [Object, String],
      default: () => '',
    },
    gradient: {
      type: String,
      default: '', // danger, primary etc
    },
    headerClasses: {
      type: [Object, String],
      default: () => '',
    },
    bodyClasses: {
      type: [Object, String],
      default: () => '',
    },
    footerClasses: {
      type: [Object, String],
      default: () => '',
    },
    animationDuration: {
      type: Number,
      default: 500,
    }
  },
  watch: {
    show(val) {
      const documentClasses = document.body.classList
      if (val) {
        documentClasses.add("modal-open")
      } else {
        documentClasses.remove("modal-open")
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit("update:show", false)
      this.$emit("close")
    }
  },
};
</script>

<template>
  <SlideYUpTransition :duration="animationDuration">
    <div
      v-show="show"
      :class="[{'show d-block': show}, {'d-none': !show}, {'modal-mini': type === 'mini'}]"
      :aria-hidden="!show"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      @click.self="closeModal"
    >
      <div
        :class="[{'modal-notice': type === 'notice'}, {'modalCentered': centered}, modalClasses]"
        class="modal-dialog  modal-lg"
      >
        <div
          :class="[gradient ? `bg-gradient-${gradient}` : '',modalContentClasses]"
          class="modal-content modalContent"
        >
          <div
            v-if="$slots.header"
            :class="[headerClasses]"
            class="modal-header"
          >
            <slot name="header" />
            <slot name="close-button">
              <button
                v-if="showClose"
                type="button"
                class="close"
                data-dismiss="modal"
                :aria-label="$t('close')"
                :title="$t('close')"
                @click="closeModal"
              >
                <i class="tim-icons icon-simple-remove" />
              </button>
            </slot>
          </div>

          <div
            v-if="$slots.default"
            :class="bodyClasses"
            class="modal-body modalBody"
          >
            <slot />
          </div>

          <div
            v-if="$slots.footer"
            :class="footerClasses"
            class="modal-footer"
          >
            <slot name="footer" />
          </div>
        </div>
      </div>
    </div>
  </SlideYUpTransition>
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

.modal.show
  background-color: transparentize($c-black, 0.7)
.modal.fade
  .modalCentered
    top: 50%
    margin: 0 auto
    transform: translate(0, -50%)
.modalBody
  padding: 0
.modalContent
  background: $c-ebony-clay
</style>

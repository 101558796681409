<script>
  export default {
    name: "BaseMultiSelect",
    props: {
      items: {
        type: Array,
        default: () => [],
        required: true,
      },
      preSelectedItems: {
        type: Array,
        default: () => [],
      },
      label: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        isMenuOpened: false,
        selectedItems: [],
        keyword: '',
        filteredItems: [],
        loaded: false,
      }
    },
    watch: {
      preSelectedItems() {
        this.selectedItems = this.preSelectedItems && this.preSelectedItems.length ? [...this.preSelectedItems] : []
      },
      items() {
        !this.loaded && this.search()
        this.loaded = true
      }
    },
    mounted() {
      this.search()
    },
    methods: {
      closeMenu() {
        this.isMenuOpened = false
      },
      openMenu() {
        this.isMenuOpened = true
      },
      removeItem(i) {
        this.selectedItems.splice(i, 1)
        this.$emit('updateSelected', this.selectedItems)
      },
      addItem(item) {
        const included = !!this.selectedItems.find(sel => sel.name === item.name)
        if(!included) {
          this.selectedItems.push(item)
          this.$emit('updateSelected', this.selectedItems)
        }
      },
      search() {
        if(!this.keyword) {
          this.filteredItems = [...this.items]
        }else{
          this.filteredItems = this.items.filter(item => {
            item = JSON.stringify(item)
            return item.includes(this.keyword)
          })
        }
      }
    }
  }
</script>

<template>
  <div class="multiSelect">
    <label>{{ label }}</label>
    <div
      v-click-outside="closeMenu"
      class="multiSelectContainer form-control"
      @click="openMenu"
    >
      <div class="selectedContainer">
        <div v-if="selectedItems.length">
          <div
            v-for="(selected, i) in selectedItems"
            :key="i + selected.id + selected.name"
            class="selectedItem"
          >
            <div class="selectedItemInner">
              <span>{{ selected.name }}</span>
              <span
                class="removeButton"
                @click="removeItem(i)"
              >
                <BaseIcon
                  icons-group="fas"
                  icon="fa-times"
                  size="s"
                />
              </span>
            </div>
          </div>
        </div>
        <div
          v-else
          class="selectedAlternative"
        >
          {{ $t('selectItem', { item: label }) }}
        </div>
        <BaseIcon
          icons-group="fas"
          icon="fa-caret-down"
          :class="['menuIcon', {'menuIconOpened': isMenuOpened}]"
        />
      </div>
      <ul :class="['menu', {'opened': isMenuOpened}]">
        <BaseInput
          v-model="keyword"
          :placeholder="$t('search')"
          @input.native="search"
        />
        <li
          v-for="(item, i) in filteredItems"
          :key="item+i+'multiselect'"
          :class="['menuItem', preSelectedItems && preSelectedItems.length && preSelectedItems.find(obj => obj.id === item.id) ? 'active': '']"
          @click="addItem(item)"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '~@/assets/sass/design.sass' as *;

.multiSelectContainer {
    height: auto;
    padding-right: 0;
    overflow: hidden;
    cursor: pointer;
}

.selected {
  &Container {
    display: grid;
    grid-template-columns: auto 20px;
    align-items: center;
  }

  &Item {
    display: inline-block;
    padding: $s-xxs;
    margin: 0 $s-xxs $s-xxs 0;
    font-size: 10px;
    border: 1px solid $c-boulder-grey;
    border-radius: 6px;

    &Inner {
      display: flex;
      align-items: center;
    }
  }

  &Alternative {
    color: $text-faded;
  }
}

.removeButton {
  display: inline-flex;
  margin-left: $s-xxs;
}

.menu {
  display: none;
  max-height: 200px;
  padding: 0;
  margin: $base-spacing 0 0;
  overflow: auto;

  &.opened {
    display: block;
  }

  &Icon {
    width: 12.5px;
    line-height: unset;

    &Opened {
      transform: rotate(180deg);
    }
  }

  &Item {
    position: relative;
    display: block;
    padding: $s-xs;
    list-style: none;

    &::before {
      @include fill;

      z-index: -1;
      content: '';
      background: $c-acc-green;
      opacity: 0;
      transition: opacity .3s ease-in-out;
    }

    &:hover::before {
        opacity: 1;
    }

    &.active {
      background: transparentize($c-acc-green, 0.8);
    }
  }
}
</style>

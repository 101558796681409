<script>
export default {
  name: 'BaseIcon',
	props: {
		icon: {
      type: String,
      default: '',
      required: true
    },
    iconsGroup: {
      type: String,
      default: 'tim-icons'
    },
    size: {
      type: String,
      default: 'm' // 'xs -> x-small', 's -> small', 'm -> medium', 'l -> large', 'xl -> x-large', 'xxl -> xx-large'
    },
  },
  methods: {
    handleClick(evt) {
      this.$emit('click', evt)
    }
  }
}
</script>

<template>
  <i
    :class="['icon', `${iconsGroup}`, `icon-${icon}`, `${icon}`, `icon${size}`]"
    @click="handleClick"
  />
</template>

<style scoped lang="sass">
@import '~@/assets/sass/design.sass'

.icon
  &::before
    display: inline-block
  &xs
    font-size: $s-xs
  &s
    font-size: $s-s
  &m
    font-size: $s-m
  &l
    font-size: $s-l
  &xl
    font-size: $s-xl
  &xxl
    font-size: $s-xxl
  &xxxl
    font-size: $s-xxxl
</style>

import settingsService from '@/services/settings.service'

export const state = {
  settings: null,
}

export const actions = {
  getGeneralSettings({ commit }) {
    settingsService.getGeneral()
      .then(res => {
        commit('SET_GENERAL_SETTINGS', res.data)
      })
  },
}

export const mutations = {
  SET_GENERAL_SETTINGS(state, settings) {
    state.settings = settings;
  },
}

export const getters = {}
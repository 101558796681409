<script>
export default {
	computed: {
		loggedIn() {
			return this.$store.getters['auth/loggedIn']
		},
		authUser() {
			return this.$store.state.auth.authUser
		},
	},
	mounted() {
		this.$watch('$sidebar.showSidebar', this.toggleNavOpen)
		if (this.loggedIn) {
			this.$store.dispatch('permissions/getPermissionsByUser', {
				user: this.authUser,
			})
			this.$store.dispatch('permissions/getAllPermissions')
			this.$store.dispatch('users/getAllUsers')
			this.$store.dispatch('users/getCurrentUser', this.authUser.id)
			this.$store.dispatch('posts/getAllCategories')
		}
	},
	methods: {
		toggleNavOpen() {
			let root = document.getElementsByTagName('html')[0]
			root.classList.toggle('nav-open')
		},
	},
}
</script>

<template>
  <div>
    <notifications />
    <router-view :key="$route.fullPath" />
  </div>
</template>

<style lang="scss">
@import '~@/assets/sass/style';
</style>

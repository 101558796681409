<script>
import { Pagination } from 'v-datatable-light'

export default {
  components: {
    Pagination,
  },
  props: {
    limit: {
      type: Number,
      required: true,
    },
    offset: {
      type: Number,
      required: true,
    },
    totalItems: {
      type: Number,
      required: true,
    },
    totalDisplayed: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      paginationCss: {
				paginationItem: 'page-item pageItem',
				pageBtn: 'page-link pageLink',
			},
    }
  },
  methods: {
    updatePagination(currentPage) {
			this.$emit('updatePagination', currentPage)
		},
  }
};
</script>

<template>
  <div class="paginationContainer">
    <Pagination
      class="pagination listTablePagination"
      :page="offset"
      :total-items="totalItems"
      :items-per-page="limit"
      :css="paginationCss"
      @on-update="updatePagination"
    />
    <div
      v-if="totalDisplayed"
      class="total"
    >
      {{ $t('displaying') }} <span class="totalNumber">{{ totalDisplayed }}</span> {{ $t('of') }} <span class="totalNumber">{{ totalItems }}</span> {{ $tc('item', 2) }}
    </div>
  </div>
</template>

<style lang="sass">
@import '~@/assets/sass/design.sass'

.listTablePagination
	margin-top: $base-spacing
	.pageItem
		&.selected
			display: block
			padding: 0 0.6875rem
			margin-left: -0.0625rem
			line-height: 30px
			background: $c-acc-green
	.pageLink
		height: 30px
</style>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

.listTablePagination
	margin-top: $base-spacing
	.pageItem
		&.selected
			display: block
			padding: 0 0.6875rem
			margin-left: -0.0625rem
			line-height: 30px
			background: $c-acc-green
	.pageLink
		height: 30px
.paginationContainer
	display: flex
	align-items: center
	justify-content: space-between
.total
	text-transform: initial
	&Number
		color: $c-acc-green
</style>


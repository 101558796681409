import i18n from './../i18n'

const sideBarLinks = [
  {
    path: '/dashboard',
    name: i18n.t('dashboard'),
    icon: 'fas fa-chart-pie',
  },
  {
    path: '/library',
    name: i18n.t('library'),
    icon: 'fas fa-photo-video',
    permission: 'library.read-list',
  },
  {
    path: '/posts',
    name: i18n.tc('post', 2),
    icon: 'far fa-newspaper',
    permission: 'post.read-list',
    submenu: [
      {
        path: '/post-categories',
        name: i18n.tc('postCategory', 2),
        icon: 'fas fa-folder-open',
        permission: 'post-category.read-list',
      },
    ]
  },
  {
    path: '/consoles',
    name: i18n.tc('console', 2),
    icon: 'fas fa-gamepad',
    permission: 'console.read-list',
    submenu: [
      {
        path: '/console-categories',
        name: i18n.tc('category', 2),
        icon: 'fas fa-cubes',
        permission: 'category.read-list',
      },
      {
        path: '/colors',
        name: i18n.t('housingColors'),
        icon: 'fas fa-paint-brush',
        permission: 'color.read-list',
      },
      {
        path: '/generations',
        name: i18n.tc('generation', 2),
        icon: 'fas fa-landmark',
        permission: 'generation.read-list',
      },
      {
        path: '/bits',
        name: i18n.tc('bit', 2),
        icon: 'fas fa-chess-board',
        permission: 'bit.read-list',
      },
      {
        path: '/palettes',
        name: i18n.tc('palette', 2),
        icon: 'fas fa-palette',
        permission: 'palette.read-list',
      },
      {
        path: '/media',
        name: i18n.tc('medium', 2),
        icon: 'fas fa-sd-card',
        permission: 'media.read-list',
      },
    ]
  },
  {
    path: '/accessories',
    name: i18n.tc('accessory', 2),
    icon: 'far fa-keyboard',
    permission: 'accessory.read-list',
    submenu: [
      {
        path: '/accessory-categories',
        name: i18n.tc('category', 2),
        icon: 'fas fa-cubes',
        permission: 'category.read-list',
      }
    ]
  },
  {
    path: '/brands',
    name: i18n.tc('brand', 2),
    icon: 'far fa-copyright',
    permission: 'brand.read-list',
  },
  {
    path: '/pages',
    name: i18n.tc('page', 2),
    icon: 'far fa-file-alt',
    permission: 'page.read-list',
  },
  {
    path: '/slideshow',
    name: i18n.t('slideshow'),
    icon: 'far fa-play-circle',
    permission: 'slide.read-list',
  },
  {
    path: '/faq',
    name: i18n.tc('faq', 2),
    icon: 'far fa-question-circle',
    permission: 'faq.read-list',
  },
  {
    path: '/affiliates',
    name: i18n.tc('affiliate', 2),
    icon: 'fas fa-search-dollar',
    permission: 'affiliate.read-list',
  },
]

export  { sideBarLinks }
<script>
import TopNavbar from './TopNavbar.vue'
import ContentFooter from './ContentFooter.vue'
import DashboardContent from './Content.vue'
import { sideBarLinks } from '@/router/router-links.js'

export default {
	components: {
		TopNavbar,
		ContentFooter,
		DashboardContent,
	},
  data() {
    return {
      sideBarLinks: sideBarLinks
    }
  },
	methods: {
		toggleSidebar() {
			if (this.$sidebar.showSidebar) {
				this.$sidebar.displaySidebar(false)
			}
		},
	},
}
</script>

<template>
  <div class="wrapper">
    <side-bar
      background-color="vue"
      :sidebar-links="sideBarLinks"
    />
    <div class="main-panel">
      <top-navbar />
      <dashboard-content @click.native="toggleSidebar" />
      <content-footer />
    </div>
  </div>
</template>
import { get, post, put, remove } from '@/services/api.service'

const PERMISSIONS_API_URL = '/roles'
const PERMISSIONS_ROLE_API_URL = '/permissions-by-role'

class RolesService {
  getAll(){
    return get(`${PERMISSIONS_API_URL}?extended=1`)
      .then(res => res)
  }
  getById({ id }) {
    return get(`${PERMISSIONS_API_URL}/${id}?extended=1`)
      .then(res => res)
  }
  getPermissionByRole(role) {
    return get(`${PERMISSIONS_ROLE_API_URL}/${role.id}?extended=1`)
      .then(res => res)
  }
  updateRolePermissions({ role, permissions }) {
    return put(`${PERMISSIONS_ROLE_API_URL}/${role.id}`, permissions)
      .then(res => res)
  }
  create({ item }) {
    return post(`${PERMISSIONS_API_URL}`, item)
    .then(res => res)
  }
  edit({ item }) {
    return put(`${PERMISSIONS_API_URL}/${item.id}`, item)
    .then(res => res)
  }
  remove(id) {
    return remove(`${PERMISSIONS_API_URL}/${id}`)
      .then(res => res)
  }
}

export default new RolesService()

<script>
import Draggable from 'vuedraggable'

export default {
  name: 'DraggableList',
  components: {
    Draggable
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    model: {
      type: String,
      default: ''
    },
    supportsPhoto: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    mutatableItems: {
      get() {
        return this.items
      },
      set(value) {
        this.$emit('updatePosition', value)
      },
    }
  },
  methods: {
    remove(id) {
			if (confirm(this.$t('m.areYouSureYouWantToDeleteThatItem'))) {
				this.$emit('remove', id)
			} else {
				this.$notify({
					message: this.$t('m.youDidNotDeleteTheItem'),
					icon: "far fa-bell",
					horizontalAlign: 'right',
					verticalAlign: 'top',
					type: 'warning',
				})
			}
			
		},
  }
}
</script>

<template>
  <ul class="list text-center">
    <li>
      <div :class="['itemInner', 'head', {'extended': supportsPhoto}]">
        <span>{{ $t('grip') }}</span>
        <span v-if="supportsPhoto">{{ $tc('photo') }}</span>
        <span class="text-left">{{ $t('title') }}</span>
        <span>{{ $t('position') }}</span>
        <span>{{ $t('edit') }}</span>
        <span>{{ $t('remove') }}</span>
      </div>
    </li>
    <Draggable
      v-model="mutatableItems"
    >
      <li
        v-for="item in items"
        :key="`slideshow${item.id}`"
        class="item"
      >
        <div :class="['itemInner', {'extended': supportsPhoto}]">
          <span class="grip">
            <BaseIcon
              icons-group="fas"
              icon="fa-grip-vertical"
            />
          </span>
          <div
            v-if="supportsPhoto"
            class="photo"
          >
            <BaseImage
              v-if="item.photo"
              :source="item"
              size="thumb"
            />
            <span v-else>
              <BaseIcon
                icons-group="far"
                icon="fa-image"
                class="icon iconImage"
                size="l"
              />
            </span>
          </div>
          <div class="text-left">
            <router-link :to="`/${model}/${item.id}`">
              <span class="title">{{ item.name }}</span>
            </router-link>
          </div>
          <span>{{ item.position }}</span>
          <router-link
            class=""
            :to="`/${model}/${item.id}`"
          >
            <BaseIcon
              icons-group="far"
              icon="fa-edit"
              class="iconEdit"
            />
          </router-link>
          <BaseIcon
            icons-group="fas"
            icon="fa-ban"
            class="iconDelete"
            @click="remove(item.id)"
          />
        </div>
      </li>
    </Draggable>
  </ul>
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

.list
  padding: 0
  list-style: none
.head
  padding: $base-spacing
.item
  padding: $base-spacing
  background: $blue-gradient
  border-radius: 8px
  &Inner
    display: grid
    grid-template-columns: 60px auto 60px 60px 60px
    grid-gap: $base-spacing
    align-items: center
    &.extended
      grid-template-columns: 60px 120px auto 60px 60px 60px

.item~.item
  margin-top: $base-spacing
.grip
  cursor: move
.photo
  position: relative
.title
  color: $c-white
  &:hover
    color: $c-acc-green
.icon
  &Edit
    color: $c-acc-blue-dark
  &Delete
    color: $c-acc-red
    cursor: pointer
  &Image
    @include center
</style>
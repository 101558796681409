import { get, post, put, remove } from '@/services/api.service';

const API_URL = '/users';
const REGISTER_API = '/register';
const USER_PERMISSIONS_URL = '/permissions-by-user'

class UserService {
  getAll() {
    return get(`${API_URL}?extended=1`)
      .then(res => res);
  }
  getById({id}) {
    return get(`${API_URL}/${id}?extended=1`)
      .then(res => res);
  }
  create(data) {
    return post(REGISTER_API, data)
      .then(res => res);
  }
  edit(data) {
    return put(`${API_URL}/${data.id}`, data)
      .then(res => res);
  }
  remove(id) {
    return remove(`${API_URL}/${id}`)
      .then(res => res);
  }
  updateUserPermissions({ permissions , id }) {
    return put(`${USER_PERMISSIONS_URL}/${id}`, permissions)
      .then(res => res);
  } 
}

export default new UserService();
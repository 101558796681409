import permissionsService from '@/services/permissions.service'

export const state = {
  permissions: [],
  currentUserPermissions: [],
}

export const actions = {
  async getAllPermissions({ commit }) {
    const res = await permissionsService.getAll()
    commit('SET_PERMISSIONS', res.data)
  },
  async getPermissionsByUser({ commit, getters }, { user, permissionToCheck }) {
    const res = await permissionsService.getByUser({ user })
    commit('SET_CURRENT_USER_PERMISSIONS', res.data)
    return permissionToCheck ? getters['checkIfUserHasPermission'](permissionToCheck) : undefined
  },
}

export const mutations = {
  SET_PERMISSIONS(state, permissions) {
    state.permissions = permissions;
  },
  SET_CURRENT_USER_PERMISSIONS(state, currentUserPermissions) {
    state.currentUserPermissions = currentUserPermissions;
  },
}

export const getters = {
  checkIfUserHasPermission: state => name => state.currentUserPermissions
    .find(permission => permission.name === name),
}
<script>
  import { CollapseTransition } from 'vue2-transitions'
  import { camelToText } from '@/utils/converters'
  import Modal from '@/components/Modal'
  import localSave from '@/utils/local-save'

  export default {
    components: {
      CollapseTransition,
      Modal
    },
    data() {
      return {
        activeNotifications: false,
        showMenu: false,
        searchModalVisible: false,
        searchQuery: ''
      }
    },
    computed: {
      routeName() {
        const { name } = this.$route
        return name
      },
      user() {
        return this.$store.state.users.currentUser || null
      },
      userPhoto() {
        return this.$store.state.users.currentUserPhoto || null
      },
      activeLanguage() {
        return localSave.get('locale') || process.env.VUE_APP_I18N_LOCALE || 'en'
      },
    },
    methods: {
      toggleNotificationDropDown() {
        this.activeNotifications = !this.activeNotifications
      },
      closeDropDown() {
        this.activeNotifications = false
      },
      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
      },
      hideSidebar() {
        this.$sidebar.displaySidebar(false)
      },
      toggleMenu() {
        this.showMenu = !this.showMenu
      },
      logout() {
        this.$store.dispatch('auth/logout')
      },
      changeLanguage(language) {
        localSave.save('locale', language)
        location.reload()
      },
      camelToText,
    }
  }
</script>

<template>
  <nav 
    :class="{'bg-white': showMenu, 'navbar-transparent': !showMenu}"
    class="navbar navbar-expand-lg navbar-absolute"
  >
    <div class="container-fluid">
      <div class="navbar-wrapper">
        <div 
          :class="{toggled: $sidebar.showSidebar}" 
          class="navbar-toggle d-inline"
        >
          <button 
            type="button"
            class="navbar-toggler"
            aria-label="Navbar toggle button"
            @click="toggleSidebar"
          >
            <span class="navbar-toggler-bar bar1" />
            <span class="navbar-toggler-bar bar2" />
            <span class="navbar-toggler-bar bar3" />
          </button>
        </div>
        <div class="navbar-brand">
          {{ camelToText(routeName) }}
        </div>
      </div>
      <button 
        class="navbar-toggler" 
        type="button"
        data-toggle="collapse"
        data-target="#navigation"
        aria-controls="navigation-index"
        aria-label="Toggle navigation"
        @click="toggleMenu"
      >
        <span class="navbar-toggler-bar navbar-kebab" />
        <span class="navbar-toggler-bar navbar-kebab" />
        <span class="navbar-toggler-bar navbar-kebab" />
      </button>

      <collapse-transition>
        <div 
          v-show="showMenu" 
          class="collapse navbar-collapse show"
        >
          <ul 
            :class="'ml-auto'" 
            class="navbar-nav"
          >
            <div 
              class="search-bar input-group" 
              @click="searchModalVisible = true"
            >
              <button 
                id="search-button" 
                class="btn btn-link" 
                data-toggle="modal" 
                data-target="#searchModal"
              >
                <i class="tim-icons icon-zoom-split" />
              </button>
            </div>
            <modal 
              id="searchModal"
              :show.sync="searchModalVisible"
              :centered="false"
              :show-close="true"
              class="modal-search"
            >
              <input 
                id="inlineFormInputGroup" 
                slot="header" 
                v-model="searchQuery" 
                type="text" 
                class="form-control" 
                :placeholder="$t('search')"
              >
            </modal>
            <BaseDropdown 
              menu-on-right
              tag="li"
              title-tag="a" 
              class="nav-item"
            >
              <a 
                slot="title" 
                href="#" 
                class="dropdown-toggle nav-link" 
                data-toggle="dropdown" 
                aria-expanded="true"
              >
                <div class="notification d-none d-lg-block d-xl-block" />
                <i class="tim-icons icon-sound-wave" />
                <p class="d-lg-none">
                  {{ $t('newNotifications') }}
                </p>
              </a>
              <li class="nav-link">
                <a 
                  href="#" 
                  class="nav-item dropdown-item"
                >Mike John responded to your email</a>
              </li>
              <li class="nav-link">
                <a 
                  href="#" 
                  class="nav-item dropdown-item"
                >You have 5 more tasks</a>
              </li>
              <li class="nav-link">
                <a 
                  href="#" 
                  class="nav-item dropdown-item"
                >Your friend Michael is in town</a>
              </li>
              <li class="nav-link">
                <a 
                  href="#" 
                  class="nav-item dropdown-item"
                >Another notification</a>
              </li>
              <li class="nav-link">
                <a 
                  href="#" 
                  class="nav-item dropdown-item"
                >Another one</a>
              </li>
            </BaseDropdown>
            <BaseDropdown 
              menu-on-right
              tag="li"
              title-tag="a" 
              class="nav-item"
            >
              <a 
                slot="title" 
                href="#" 
                class="dropdown-toggle nav-link" 
                data-toggle="dropdown" 
                aria-expanded="true"
              >
                <div class="d-none d-lg-block d-xl-block" />
                <i class="tim-icons icon-planet" />
                <p class="d-lg-none">
                  {{ $t('language') }}
                </p>
              </a>
              <li
                class="nav-link"
                @click="changeLanguage('en')"
              >
                <a 
                  href="#" 
                  class="nav-item dropdown-item"
                >English</a>
              </li>
              <li
                class="nav-link"
                @click="changeLanguage('el')"
              >
                <a 
                  href="#" 
                  class="nav-item dropdown-item"
                >Ελληνικά</a>
              </li>
            </BaseDropdown>
            <BaseDropdown 
              menu-on-right
              tag="li"
              title-tag="a"
              class="nav-item"
              menu-classes="dropdown-navbar"
            >
              <span
                slot="title"
                class="dropdown-toggle nav-link" 
                data-toggle="dropdown" 
                aria-expanded="true"
              >
                <div class="photo">
                  <BaseImage
                    v-if="userPhoto"
                    :source="userPhoto"
                    size="mini"
                  />
                  <img
                    v-else
                    src="/img/default-avatar.png"
                  >
                </div>
                <b class="caret d-none d-lg-block d-xl-block" />
                <p
                  class="d-lg-none mobileMenuTitle"
                  @click="logout()"
                >
                  {{ $t('logout') }}
                </p>
              </span>
              <li class="nav-link">
                <span class="nav-item dropdown-item">
                  {{ $t('hello') }} <strong>{{ user.name || user.username }}</strong>
                </span>
              </li>
              <div class="dropdown-divider" />
              <li
                v-if="user && user.id"
                class="nav-link"
              >
                <router-link
                  class="nav-item dropdown-item"
                  :to="{ name: 'singleUser', params: { id: user.id } }"
                >
                  {{ $tc('profile') }}
                </router-link>
              </li>
              <li
                v-if="$store.getters['permissions/checkIfUserHasPermission']('user.read-list')"
                class="nav-link"
              >
                <router-link
                  class="nav-item dropdown-item"
                  to="/users"
                >
                  {{ $tc('user', 2) }}
                </router-link>
              </li>
              <li
                v-if="$store.getters['permissions/checkIfUserHasPermission']('setting.read')"
                class="nav-link"
              >
                <router-link
                  class="nav-item dropdown-item"
                  to="/settings"
                >
                  {{ $tc('setting', 2) }}
                </router-link>
              </li>
              <li
                v-if="$store.getters['permissions/checkIfUserHasPermission']('permissions.read-list')"
                class="nav-link"
              >
                <router-link
                  class="nav-item dropdown-item"
                  to="/permissions"
                >
                  {{ $tc('permission', 2) }}
                </router-link>
              </li>
              <li
                v-if="$store.getters['permissions/checkIfUserHasPermission']('role.read-list')"
                class="nav-link"
              >
                <router-link
                  class="nav-item dropdown-item"
                  to="/roles"
                >
                  {{ $tc('role', 2) }}
                </router-link>
              </li>
              <li
                v-if="$store.getters['permissions/checkIfUserHasPermission']('social.read-list')"
                class="nav-link"
              >
                <router-link
                  class="nav-item dropdown-item"
                  to="/social"
                >
                  {{ $t('socialMedia') }}
                </router-link>
              </li>
              <li
                v-if="$store.getters['permissions/checkIfUserHasPermission']('newsletter.read-list')"
                class="nav-link"
              >
                <router-link
                  class="nav-item dropdown-item"
                  to="/newsletter-subscribers"
                >
                  {{ $tc('subscriber', 2) }}
                </router-link>
              </li>
              <div class="dropdown-divider" />
              <li
                class="nav-link"
                @click="logout()"
              >
                <span class="nav-item dropdown-item">{{ $t('logout') }}</span>
              </li>
            </BaseDropdown>
          </ul>
        </div>
      </collapse-transition>
    </div>
  </nav>
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

.mobileMenuTitle
  color: $c-black
</style>
import userService from '@/services/users.service'
import libraryService from '@/services/library.service'

export const state = {
  users: [],
  currentUser: () => null,
  currentUserPhoto: null
}

export const actions = {
  getAllUsers({ commit }) {
    userService.getAll()
      .then(res => {
        commit('SET_USERS', res.data)
      })
  },
  getCurrentUser({ commit, dispatch }, id) {
    userService.getById({ id })
      .then(res => {
        commit('SET_CURRENT_USER', res.data)
        res.data.library_id && dispatch('getCurrentUserPhoto', res.data.library_id)
      })
  },
  getCurrentUserPhoto({ commit }, id) {
    libraryService.getById({ id })
      .then(res => {
        commit('SET_CURRENT_USER_PHOTO', res.data)
      })
  },
  clearUsers({ commit }) {
    commit('SET_CURRENT_USER_PHOTO', null)
    commit('SET_CURRENT_USER', null)
    commit('SET_USERS', [])
  }
}

export const mutations = {
  SET_USERS(state, users) {
    state.users = users;
  },
  SET_CURRENT_USER(state, user) {
    state.currentUser = user;
  },
  SET_CURRENT_USER_PHOTO(state, photo) {
    state.currentUserPhoto = photo;
  }
}

export const getters = {}
<script>
import Logo from '@/components/Logo'

const title = process.env.VUE_APP_TITLE

export default {
	components: {
		Logo,
  },

  props: {
    title: {
      type: String,
      default: title,
    }
  }
}
</script>

<template>
  <div class="container">
    <div class="content">
      <div class="header">
        <h1 class="title">
          <Logo class="logo" />
          {{ title }}
        </h1>
      </div>
      <slot />
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

.container
  display: flex
  align-items: center
  justify-content: center
  height: 100vh
.content
  width: 370px
  margin: auto
.header
  margin-bottom: $base-spacing
.logo
  width: $s-xxl
  margin: $s-l auto
  text-align: center
.title
  margin: 0
  font-size: $s-l
  text-align: center

.button
  margin-top: $base-spacing
</style>

import { get, post, put, remove } from '@/services/api.service'

const POSTS_API_URL = '/posts'
const POST_CATEGORIES_URL = '/post-categories'

class PostsService {
  getAll({ category, author, limit, offset, keyword }){
    const catParam = category && `&category=${category}` || ''
    const authorParam = author && `&author=${author}` || ''
    const limitParam = limit && `&limit=${limit}` || ''
    const offsetParam = offset && `&offset=${offset - 1}` || ''
    const searchParam = keyword ? `&search=${keyword}` : ''
    const parameters = `?${catParam}${authorParam}${limitParam}${offsetParam}${searchParam}&extended=1`
    return get(`${POSTS_API_URL}${parameters}`)
      .then(res => res)
  }
  getById({ id }) {
    return get(`${POSTS_API_URL}/${id}?extended=1`)
      .then(res => res)
  }
  create({ data }) {
    return post(`${POSTS_API_URL}`, data)
    .then(res => res)
  }
  edit({ data }) {
    return put(`${POSTS_API_URL}/${data.id}`, data)
    .then(res => res)
  }
  remove({ id }) {
    return remove(`${POSTS_API_URL}/${id}`)
      .then(res => res)
  }

  // CATEGORIES
  getAllCategories() {
    return get(`${POST_CATEGORIES_URL}?extended=1`)
      .then(res => res)
  }
  getCategoryById({ id }) {
    return get(`${POST_CATEGORIES_URL}/${id}?extended=1`)
      .then(res => res)
  }
  createCategory({ data }) {
    return post(`${POST_CATEGORIES_URL}`, data)
      .then(res => res)
  }
  editCategory({ data }) {
    return put(`${POST_CATEGORIES_URL}/${data.id}`, data)
      .then(res => res)
  }
  removeCategory({ id }) {
    return remove(`${POST_CATEGORIES_URL}/${id}`)
      .then(res => res)
  }
}

export default new PostsService()
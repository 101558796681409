import PostsService from '@/services/posts.service'


export const state = {
  categories: [],
  currentPost: () => {},
}

export const actions = {
  getAllCategories({ commit }) {
    PostsService.getAllCategories()
      .then(res => {
        commit('SET_CATEGORIES', res.data)
      })
  },
  getCurrentPost({ commit }, id) {
    PostsService.getById(id)
      .then(res => {
        commit('SET_CURRENT_POAR', res.data)
      })
  }
}

export const mutations = {
  SET_CATEGORIES(state, categories) {
    state.categories = categories;
  },
  SET_CURRENT_POST(state, post) {
    state.currentPost = post;
  },
}

export const getters = {}
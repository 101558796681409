import store from '@/store'
import DashboardLayout from '@/layout/dashboard/DashboardLayout.vue'
// GeneralViews
import NotFound from '@/pages/NotFoundPage'
import Login from '@/pages/Auth/Login'
import ForgotPassword from '@/pages/Auth/ForgotPassword'
import ResetPassword from '@/pages/Auth/ResetPassword'

// Dashboard
const Dashboard = () => import('@/pages/Dashboard')

// Library
const Library = () => import('@/pages/Library')
const SingleLibrary = () => import('@/pages/Library/_id')

// Posts
const Posts = () => import('@/pages/Posts')
const SinglePost = () => import('@/pages/Posts/_id')
const PostCategories = () => import('@/pages/PostCategories')
const SinglePostCategory = () => import('@/pages/PostCategories/_id')

// Consoles
const Consoles = () => import('@/pages/Consoles')
const SingleConsole = () => import('@/pages/Consoles/_id')
const ConsoleCategories = () => import('@/pages/Consoles/ConsoleCategories')
const SingleConsoleCategory = () => import('@/pages/Consoles/ConsoleCategories/_id')
const Colors = () => import('@/pages/Consoles/Colors')
const SingleColor = () => import('@/pages/Consoles/Colors/_id')
const Generations = () => import('@/pages/Consoles/Generations')
const SingleGeneration = () => import('@/pages/Consoles/Generations/_id')
const Bits = () => import('@/pages/Consoles/Bits')
const SingleBit = () => import('@/pages/Consoles/Bits/_id')
const Media = () => import('@/pages/Consoles/Media')
const SingleMedium = () => import('@/pages/Consoles/Media/_id')
const Palettes = () => import('@/pages/Consoles/Palettes')
const SinglePalette = () => import('@/pages/Consoles/Palettes/_id')

// Accessories
const Accessories = () => import('@/pages/Accessories')
const AccessoryCategories = () => import('@/pages/Accessories/AccessoryCategories')
const SingleAccessoryCategory = () => import('@/pages/Accessories/AccessoryCategories/_id')
const SingleAccessory = () => import('@/pages/Accessories/_id')

// Brands
const Brands = () => import('@/pages/Brands')
const SingleBrand = () => import('@/pages/Brands/_id')

// Pages
const Pages = () => import('@/pages/Pages')
const SinglePage = () => import('@/pages/Pages/_id')

// Slideshow
const Slideshow = () => import('@/pages/Slideshow')
const SingleSlideshow = () => import('@/pages/Slideshow/_id')

// FAQ
const Faq = () => import('@/pages/Faq')
const SingleFaq = () => import('@/pages/Faq/_id')

// Affiliates
const Affiliates = () => import('@/pages/Affiliates')
const SingleAffiliate = () => import('@/pages/Affiliates/_id')

// Settings, Users, Social, etc.
const Settings = () => import('@/pages/Settings')
const Users = () => import('@/pages/Users')
const SingleUser = () => import('@/pages/Users/_id')
const Social = () => import('@/pages/Social')
const SingleSocial = () => import('@/pages/Social/_id')

// Permissions
const Permissions = () => import('@/pages/Permissions')
const SinglePermission = () => import('@/pages/Permissions/_id')

// Roles
const Roles = () => import('@/pages/Roles')
const SingleRole = () => import('@/pages/Roles/_id')

// Newsletter - Subscribers
const NewsletterSubscribers = () => import ('@/pages/NewsletterSubscribers')


const message = `You don't have permissions to access this page yet young Sith.`

const handleReject = next => {
  next('/')
  window.notify({
    message: message,
    icon: "far fa-bell",
    horizontalAlign: 'right',
    verticalAlign: 'top',
    type: 'warning',
  })
}

const checkPermission = async permission => {
  await store.dispatch('permissions/getPermissionsByUser', { user: store.state.auth.authUser, permissionToCheck: permission }) 
}


const routes = [
  { 
    path: '/',
    redirect: store.getters['auth/loggedIn'] ? '/dashboard' : '/login',
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    props: true,
    beforeEnter(routeTo, routeFrom, next) {
      if (store.getters['auth/loggedIn']) {
        next({ name: 'dashboard' })
      } else {
        next()
      }
    },
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: ForgotPassword,
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
    component: ResetPassword,
  },
  {
    path: '/dashboard',
    component: DashboardLayout,
    meta: { 
      requiresAuth: true
    },
    children: [
      // Dashboard
      {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
      },
      // Library
      {
        path: '/library',
        name: 'library',
        component: Library,
        beforeEnter(routeTo, routeFrom, next) {
          checkPermission('library.read-list') ? next() : handleReject(next)
        },
      },
      {
        path: '/library/:id',
        name: 'singleLibrary',
        component: SingleLibrary,
        beforeEnter(routeTo, routeFrom, next) {
          checkPermission('library.read') ? next() : handleReject(next)
        },
      },
      // Posts
      {
        path: '/posts',
        name: 'posts',
        component: Posts,
        beforeEnter(routeTo, routeFrom, next) {
          checkPermission('post.read-list') ? next() : handleReject(next)
        },
      },
      {
        path: '/posts/:id',
        name: 'singlePost',
        component: SinglePost,
        beforeEnter(routeTo, routeFrom, next) {
          checkPermission('post.read') ? next() : handleReject(next)
        },
      },
      {
        path: '/post-categories',
        name: 'postCategories',
        component: PostCategories,
        beforeEnter(routeTo, routeFrom, next) {
          checkPermission('post-category.read-list') ? next() : handleReject(next)
        },
      },
      {
        path: '/post-categories/:id',
        name: 'singlePostCategory',
        component: SinglePostCategory,
        beforeEnter(routeTo, routeFrom, next) {
          checkPermission('post-category.read') ? next() : handleReject(next)
        },
      },
      // Consoles
      {
        path: '/consoles',
        name: 'consoles',
        component: Consoles,
        beforeEnter(routeTo, routeFrom, next) {
          checkPermission('console.read-list') ? next() : handleReject(next)
        },
      },
      {
        path: '/consoles/:id',
        name: 'singleConsole',
        component: SingleConsole,
        
        beforeEnter(routeTo, routeFrom, next) {
          checkPermission('console.read') ? next() : handleReject(next)
        },
      },
      {
        path: '/console-categories',
        name: 'consoleCategories',
        component: ConsoleCategories,
        beforeEnter(routeTo, routeFrom, next) {
          checkPermission('category.read-list') ? next() : handleReject(next)
        },
      },
      {
        path: '/console-categories/:id',
        name: 'singleConsoleCategory',
        component: SingleConsoleCategory,
        beforeEnter(routeTo, routeFrom, next) {
          checkPermission('category.read') ? next() : handleReject(next)
        },
      },
      {
        path: '/colors',
        name: 'colors',
        component: Colors,
        beforeEnter(routeTo, routeFrom, next) {
          checkPermission('color.read-list') ? next() : handleReject(next)
        },
      },
      {
        path: '/colors/:id',
        name: 'singleColor',
        component: SingleColor,
        beforeEnter(routeTo, routeFrom, next) {
          checkPermission('color.read') ? next() : handleReject(next)
        },
      },
      {
        path: '/generations',
        name: 'generations',
        component: Generations,
        beforeEnter(routeTo, routeFrom, next) {
          checkPermission('generation.read-list') ? next() : handleReject(next)
        },
      },
      {
        path: '/generations/:id',
        name: 'singleGeneration',
        component: SingleGeneration,
        beforeEnter(routeTo, routeFrom, next) {
          checkPermission('generation.read') ? next() : handleReject(next)
        },
      },
      {
        path: '/bits',
        name: 'bits',
        component: Bits,
        beforeEnter(routeTo, routeFrom, next) {
          checkPermission('bit.read-list') ? next() : handleReject(next)
        },
      },
      {
        path: '/bits/:id',
        name: 'singleBit',
        component: SingleBit,
        beforeEnter(routeTo, routeFrom, next) {
          checkPermission('bit.read') ? next() : handleReject(next)
        },
      },
      {
        path: '/media',
        name: 'media',
        component: Media,
        beforeEnter(routeTo, routeFrom, next) {
          checkPermission('media.read-list') ? next() : handleReject(next)
        },
      },
      {
        path: '/media/:id',
        name: 'singleMedium',
        component: SingleMedium,
        beforeEnter(routeTo, routeFrom, next) {
          checkPermission('media.read') ? next() : handleReject(next)
        },
      },
      {
        path: '/palettes',
        name: 'palettes',
        component: Palettes,
        beforeEnter(routeTo, routeFrom, next) {
          checkPermission('palette.read-list') ? next() : handleReject(next)
        },
      },
      {
        path: '/palettes/:id',
        name: 'singlePalette',
        component: SinglePalette,
        beforeEnter(routeTo, routeFrom, next) {
          checkPermission('palette.read') ? next() : handleReject(next)
        },
      },
      // Accessories
      {
        path: '/accessories',
        name: 'accessories',
        component: Accessories,
        beforeEnter(routeTo, routeFrom, next) {
          checkPermission('accessory.read-list') ? next() : handleReject(next)
        },
      },
      {
        path: '/accessory-categories',
        name: 'accessoryCategories',
        component: AccessoryCategories,
        beforeEnter(routeTo, routeFrom, next) {
          checkPermission('category.read-list') ? next() : handleReject(next)
        },
      },
      {
        path: '/accessory-categories/:id',
        name: 'singleAccessoryCategory',
        component: SingleAccessoryCategory,
        beforeEnter(routeTo, routeFrom, next) {
          checkPermission('category.read') ? next() : handleReject(next)
        },
      },
      {
        path: '/accessories/:id',
        name: 'singleAccessory',
        component: SingleAccessory,
        beforeEnter(routeTo, routeFrom, next) {
          checkPermission('accessory.read') ? next() : handleReject(next)
        },
      },
      // Brands
      {
        path: '/brands',
        name: 'brands',
        component: Brands,
        beforeEnter(routeTo, routeFrom, next) {
          checkPermission('brand.read-list') ? next() : handleReject(next)
        },
      },
      {        
        path: '/brands/:id',
        name: 'singleBrand',
        component: SingleBrand,
        beforeEnter(routeTo, routeFrom, next) {
          checkPermission('brand.read') ? next() : handleReject(next)
        },
      },
      // Pages
      {
        path: '/pages',
        name: 'pages',
        component: Pages,
        beforeEnter(routeTo, routeFrom, next) {
          checkPermission('page.read-list') ? next() : handleReject(next)
        },
      },
      {
        path: '/pages/:id',
        name: 'singlePage',
        component: SinglePage,
        beforeEnter(routeTo, routeFrom, next) {
          checkPermission('page.read') ? next() : handleReject(next)
        },
      },
      // Slideshow
      {
        path: '/slideshow',
        name: 'slideshow',
        component: Slideshow,
        beforeEnter(routeTo, routeFrom, next) {
          checkPermission('slideshow.read-list') ? next() : handleReject(next)
        },
      },
      {
        path: '/slideshow/:id',
        name: 'singleSlideshow',
        component: SingleSlideshow,
        beforeEnter(routeTo, routeFrom, next) {
          checkPermission('slideshow.read') ? next() : handleReject(next)
        },
      },
      // FAQ
      {
        path: '/faq',
        name: 'faq',
        component: Faq,
        beforeEnter(routeTo, routeFrom, next) {
          checkPermission('faq.read-list') ? next() : handleReject(next)
        },
      },
      {
        path: '/faq/:id',
        name: 'singleFaq',
        component: SingleFaq,
        beforeEnter(routeTo, routeFrom, next) {
          checkPermission('faq.read') ? next() : handleReject(next)
        },
      },
      // Affiliates
      {
        path: '/affiliates',
        name: 'affiliates',
        component: Affiliates,
        beforeEnter(routeTo, routeFrom, next) {
          checkPermission('affiliate.read-list') ? next() : handleReject(next)
        },
      },
      {
        path: '/affiliates/:id',
        name: 'singleAffiliate',
        component: SingleAffiliate,
        beforeEnter(routeTo, routeFrom, next) {
          checkPermission('affiliate.read') ? next() : handleReject(next)
        },
      },
      // Users, Settings, Social
      {
        path: '/users',
        name: 'users',
        component: Users,
        beforeEnter(routeTo, routeFrom, next) {
          checkPermission('user.read-list') ? next() : handleReject(next)
        },
      },
      {
        path: '/users/:id',
        name: 'singleUser',
        component: SingleUser,
        beforeEnter(routeTo, routeFrom, next) {
          checkPermission('user.read') ? next() : handleReject(next)
        },
      },
      {
        path: '/settings',
        name: 'settings',
        component: Settings,
        beforeEnter(routeTo, routeFrom, next) {
          checkPermission('setting.read') ? next() : handleReject(next)
        },
      },
      {
        path: '/social',
        name: 'social',
        component: Social,
        beforeEnter(routeTo, routeFrom, next) {
          checkPermission('social.read-list') ? next() : handleReject(next)
        },
      },
      {
        path: '/social/:id',
        name: 'singleSocial',
        component: SingleSocial,
        beforeEnter(routeTo, routeFrom, next) {
          checkPermission('social.read') ? next() : handleReject(next)
        },
      },
      // Permissions
      {
        path: '/permissions',
        name: 'permissions',
        component: Permissions,
        beforeEnter(routeTo, routeFrom, next) {
          checkPermission('permissions.read-list') ? next() : handleReject(next)
        },
      },
      {
        path: '/permissions/:id',
        name: 'singlePermission',
        component: SinglePermission,
        beforeEnter(routeTo, routeFrom, next) {
          checkPermission('permissions.read') ? next() : handleReject(next)
        },
      },
      // Roles
      {
        path: '/roles',
        name: 'roles',
        component: Roles,
        beforeEnter(routeTo, routeFrom, next) {
          checkPermission('role.read-list') ? next() : handleReject(next)
        },
      },
      {
        path: '/roles/:id',
        name: 'singleRole',
        component: SingleRole,
        beforeEnter(routeTo, routeFrom, next) {
          checkPermission('role.read') ? next() : handleReject(next)
        },
      },

      // Newsletter - Subscribers
      {
        path: '/newsletter-subscribers',
        name: 'newsletter-subscribers',
        component: NewsletterSubscribers,
        beforeEnter(routeTo, routeFrom, next) {
          checkPermission('newsletter.read-list') ? next() : handleReject(next)
        },
      },
    ],
  },
  { path: '*', component: NotFound },
]

export default routes

import ClickOutside from '@/directives/ClickOutside';
import LazyLoad from '@/directives/LazyLoad';

/**
 * You can register global directives here and use them as a plugin in your main Vue instance
 */

const GlobalDirectives = {
  install (Vue) {
    Vue.directive('click-outside', ClickOutside);
    Vue.directive('lazyload', LazyLoad);
  }
}

export default GlobalDirectives

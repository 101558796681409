import { get, post, put, remove } from '@/services/api.service'

const PERMISSIONS_API_URL = '/permissions'
const PERMISSIONS_USER_API_URL = '/permissions-by-user'

class PermissionsService {
  getAll(){
    return get(`${PERMISSIONS_API_URL}?extended=1`)
      .then(res => res)
  }
  getByUser({ user }) {
    return get(`${PERMISSIONS_USER_API_URL}/${user.id}?extended=1`)
      .then(res => res)
  }
  getById({ id }) {
    return get(`${PERMISSIONS_API_URL}/${id}?extended=1`)
      .then(res => res)
  }
  create({ item }) {
    return post(`${PERMISSIONS_API_URL}`, item)
    .then(res => res)
  }
  edit({ item }) {
    return put(`${PERMISSIONS_API_URL}/${item.id}`, item)
    .then(res => res)
  }
  remove(id) {
    return remove(`${PERMISSIONS_API_URL}/${id}`)
      .then(res => res)
  }
}

export default new PermissionsService()

import localSave from '@/utils/local-save.js'
import AuthService from '@/services/auth.service'

export const state = {
  authUser: localSave.get('authUser'),
  error: ''
}

export const actions = {

  login({ commit }, user) {
    return AuthService.login(user)
      .then( value => {
          value && commit('SET_AUTH_USER', value)
      })
      .catch(error => {
        commit('DELETE_AUTH_USER', error)
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({ ...error })
      })
  },

  logout({ commit, dispatch }) {
    return AuthService.logout()
      .then(_res => {
        commit('DELETE_AUTH_USER')
        dispatch('users/clearUsers', null, { root: true })
        location.reload()
      })
      .catch(error => {
        console.log('ERROR:', error)
        commit('DELETE_AUTH_USER')
        dispatch('users/clearUsers', null, { root: true })
        location.reload()
      })
  },

  validate({ dispatch }) {
    AuthService.isAuthenticated()
      .then(_res => {
        return true
      })
      .catch(error => {
        if (error.response.status === 401) {
          dispatch('logout', null)
        }
        return null
      })
  }
  
}

export const mutations = {
  SET_AUTH_USER(state, value) {
    state.authUser = value;
    localSave.save('authUser', value)
  },
  DELETE_AUTH_USER(state, error) {
    state.authUser = null;
    localSave.delete('authUser')
    state.error = error;
  },
}

  export const getters = {
    loggedIn(state) {
      return !!state.authUser
    },
  }
import { get, post } from '@/services/api.service';
const AUTH_URL = '/login';
const AUTH_CHECK_URL = '/authenticate'
const LOGOUT_URL = '/logout'
const FORGOT_PASSWORD_URL = '/forgot-password'
const RESET_PASSWORD_URL = '/reset-password'

class AuthService {
  login(user) {
    return post(AUTH_URL, {
        email: user.email,
        password: user.password
      })
      .then(response => {
        return response.data;
      })
  }

  register(user) {
    return post(AUTH_URL + 'signup', {
      username: user.username,
      email: user.email,
      password: user.password
    });
  }

  isAuthenticated() {
    return get(`${AUTH_CHECK_URL}?extended=1`)
      .then(res => res)
  }

  logout() {
    return post(LOGOUT_URL)
      .then(res => res)
  }

  forgotPassword(email) {
    return post(FORGOT_PASSWORD_URL, { email })
      .then(res => res)
  }

  resetPassword(model) {
    return post(RESET_PASSWORD_URL, model)
      .then(res => res)
  }
}

export default new AuthService();

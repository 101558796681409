<script>
import AuthLayout from '@/layout/AuthLayout'

export default {
	components: {
		AuthLayout,
	},
	data() {
		return {
      user: {},
		}
	},
	computed: {
		loggedIn() {
			return this.$store.getters['auth/loggedIn']
		},
	},
	mounted() {
		this.loggedIn && this.$router.push('/dashboard')
	},
	methods: {
		handleLogin() {
			this.user.email && this.user.password && this._login()
		},
		_login() {
			this.$store
				.dispatch('auth/login', this.user)
				.then(res => {
					location.reload()
				})
		},
	},
}
</script>

<template>
  <AuthLayout :title="$tc('login')">
    <form
      name="form"
      @submit.prevent="handleLogin"
    >
      <BaseInput
        v-model="user.email"
        :label="$t('emailOrUsername')"
        :placeholder="$t('enterYourEmailOrUsername')"
        type="text"
        autocomplete="email"
      />
      <BaseInput
        v-model="user.password"
        :label="$t('password')"
        :placeholder="$t('enterYourPassword')"
        type="password"
        autocomplete="password"
      />
      <BaseButton
        slot="footer"
        type="blue"
        gradient
        native-type="submit"
        class="button"
        fill
        @click="() => {}"
      >
        {{ $tc('login') }}
      </BaseButton>
    </form>
    <br>
    <div>{{ $t('forgotYourPassword') }}</div>
    <router-link :to="{ name: 'forgotPassword' }">
      {{ $t('clickHereToResetYourPassword') }}
    </router-link>
  </AuthLayout>
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

</style>

import VueRouter from 'vue-router'
import routes from './routes'
import store from '@/store'

// configure router
const router = new VueRouter({
	routes, // short for routes: routes
	linkExactActiveClass: 'active',
	mode: 'history',
	scrollBehavior: to => {
		if (to.hash) {
			return { selector: to.hash }
		} else {
			return { x: 0, y: 0 }
		}
	},
})

router.beforeEach((to, from, next) => {
	if(store.getters['auth/loggedIn']) {
		store.dispatch('auth/validate')
	}
	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (store.getters['auth/loggedIn']) {
			next()
			return
		}
		next('/login')
	} else {
		next()
	}
})

export default router

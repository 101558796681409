<script>
import Notification from './Notification'

export default {
  components: {
    Notification
  },
  props: {
    transitionName: {
      type: String,
      default: 'list'
    },
    transitionMode: {
      type: String,
      default: 'in-out'
    },
    overlap: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      notifications: this.$notifications.state
    }
  },
  watch: {
    overlap: function(newVal) {
      this.$notifications.settings.overlap = newVal
    }
  },
  created() {
    this.$notifications.settings.overlap = this.overlap
  },
  methods: {
    removeNotification(timestamp) {
      this.$notifications.removeNotification(timestamp)
    }
  },
}
</script>

<template>
  <div class="notifications">
    <transition-group 
      :name="transitionName"
      :mode="transitionMode"
    >
      <notification
        v-for="notification in notifications"
        :key="notification.timestamp.getTime()"
        v-bind="notification"
        :click-handler="notification.onClick"
        @close="removeNotification"
      />
    </transition-group>
  </div>
</template>

<style lang="sass">
.notifications
  .list-enter-active
    transition: transform 0.2s ease-in, opacity 0.4s ease-in
  .list-leave-active
    transition: transform 1s ease-out, opacity 0.4s ease-out
  .list-enter
    opacity: 0
    transform: scale(1.1)
  .list-leave-to
    opacity: 0
    transform: scale(1.2, 0.7)
</style>

<script>
export default {
  data() {
    return {
      title: process.env.VUE_APP_FULL_TITLE,
    }
  }
};
</script>

<template>
  <div class="contact-us full-screen">
    <nav class="navbar navbar-ct-default">
      <div class="container">
        <div class="navbar-header">
          <router-link
            :to="{path:'/'}"
            class="navbar-brand"
          >
            {{ title }}
          </router-link>
        </div>
      </div>
    </nav>
    <div class="wrapper wrapper-full-page section content">
      <div class="">
        <div class="container">
          <div class="row">
            <div class="col-md-8 col-md-offset-2 text-center">
              <h2 class="title text-danger">
                {{ $t('notFound', { item: 404 }) }}
              </h2>
              <h2 class="title">
                {{ $t('m.oopsThatPageDoesntExist') }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MiniLogo from '@/assets/images/game-medium-white-logo-with-gradient-background.png'

const title = process.env.VUE_APP_TITLE

export default {
  name: 'Logo',
  data() {
    return {
      logo: MiniLogo,
      title,
    }
  },
  computed: {
    settings() {
      return this.$store.state.settings.settings
    },
  }
}
</script>

<template>
  <div class="logoContainer">
    <BaseImage
      :source="logo"
      model=""
      :alt="`${title} ${$t('logo')}`"
      size=""
    />
  </div>
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

</style>
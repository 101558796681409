import axios from 'axios'
import localSave from '@/utils/local-save.js'
import i18n from './../i18n'

const BASE_URL = process.env.VUE_APP_API_BASE_URL
const CURRENT_USER = localSave.get('authUser') || null
const TOKEN = CURRENT_USER ? CURRENT_USER.token : null
const AUTH_TOKEN = (CURRENT_USER && TOKEN) && `Bearer ${TOKEN}` || ''

const axiosInstance = axios.create({ baseURL: BASE_URL })

if(AUTH_TOKEN) {
    axiosInstance.defaults.headers.common['Authorization'] = AUTH_TOKEN
}

axiosInstance.interceptors.response.use(
    response => successHandler(response),
    error => errorHandler(error)
)

const successHandler = response => response
const errorHandler = error => {
    const status = error.response.status
    if(status === 403) {
        window.notify({
            message: i18n.t('m.youHaveNoPermissionsToPerformThatTask'),
            icon: "far fa-bell",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'danger',
        })
    }
    else if(status === 401) {
        window.notify({
            message: i18n.t('m.youAreNotAuthenticated'),
            icon: "far fa-bell",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'danger',
        })
    }
    else {
        window.notify({
            message: error.response.data.message || i18n.t('m.oopsSomethingWentWrongServer'),
            icon: "far fa-bell",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'danger',
        })
    }
    return Promise
        .reject(error)
}

const get = apiLink => axiosInstance.get(apiLink)
const post = (apiLink, data, params) => axiosInstance.post(apiLink, data, params)
const put = (apiLink, data) => axiosInstance.put(apiLink, data)
const remove = id => axiosInstance.delete(id)

export { get, post, put, remove }
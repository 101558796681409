import SideBar from '@/components/SidebarPlugin'
import Notify from '@/components/NotificationPlugin'
import GlobalDirectives from './globalDirectives'

import '@/assets/sass/black-dashboard.scss'
import '@/assets/css/nucleo-icons.css'

export default {
  install(Vue) {
    Vue.use(GlobalDirectives)
    Vue.use(SideBar)
    Vue.use(Notify)
  },
}
